import { Breadcrumb } from 'gatsby-plugin-breadcrumb/components'
import React from 'react'

import SEO from '../../components/SEO'
import { BreadcrumbWrapper, Divider, H1, Item, ItemWrapper } from '../../styles/common/Element'
import { Col, Container, Row } from '../../styles/common/Layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion, faCalendarAlt, faUserClock } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components'
import ContactBanner from '../../components/Banner/ContactBanner'
import Hero from '../../components/Hero'
import mq from '../../styles/media-queries'

const H1Center = styled(H1)`
  text-align: center;
  margin-top: 2rem;
`
const ContentWrapper = styled.div`
  padding: 20px;
`

const JobItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 3rem 0 0;
  ${mq.md} {
    flex-direction: row;
  }
`

const JobItem = styled.div`
  display: flex;
  width: 250px;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2rem;

  ${mq.md} {
    flex-direction: column;
  }

  h1, p {
    text-align: center;
  }

  h1 {
    font-size: 2rem;
    margin: 1rem 0;
  }

`

const IconWrapperCircle = styled.div`
  background-color: var(--mainColor);
  width: 70px;
  height: 70px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-self: center;

    svg {
      align-self: center;
      color: white;
    }
`

export default function StellenangebotePage({ pageContext, data }) {
  const {
    breadcrumb: { crumbs },
  } = pageContext
  return (
    <>
      <SEO
        title={"Stellenangebote"}
      />
      <Hero slug={'stellenangebote'} small={true}>
        <h1 className="headline">Stellenangebote</h1>
        <h2 className="sub-headline">Wir suchen dich!</h2>
      </Hero>
      <Container>
        <Row>
          <Col>
            <BreadcrumbWrapper>
              <Breadcrumb crumbs={crumbs} crumbLabel={'Stellenangebote'} />
            </BreadcrumbWrapper>
          </Col>
        </Row>
        <Row className="f-direction-col">
          <Col>
            <H1Center>PHYSIOTHERAPEUT -/IN (M/W/D) GESUCHT</H1Center>
            <JobItemWrapper>
              <JobItem>
                <IconWrapperCircle>
                  <FontAwesomeIcon icon={faQuestion} size="3x" />
                </IconWrapperCircle>
                <H1>Wen suchen wir?</H1>
                <p>Physiotherapeut -/in mit abgeschlossener Berufsausbildung</p>
              </JobItem>
              <JobItem size={'32%'} padding={'0'}>
                <IconWrapperCircle>
                  <FontAwesomeIcon icon={faUserClock} size="3x" />
                </IconWrapperCircle>
                <H1>Für was?</H1>
                <p>Voll – oder Teilzeit</p>
              </JobItem>
              <JobItem>
                <IconWrapperCircle>
                  <FontAwesomeIcon icon={faCalendarAlt} size="3x" />
                </IconWrapperCircle>
                <H1>Ab Wann?</H1>
                <p>Ab Sofort</p>
              </JobItem>
            </JobItemWrapper>
            <Divider />
            <p>Bewerbungen von Berufseinsteigern werden berücksichtigt.<br />
              <br />
              Sollten wir Ihr Interesse geweckt haben, senden Sie Ihre schriftliche Bewerbung per Post oder E-Mail an nachfolgende Adresse. </p>
            <p><strong><span>Physiotherapie Stefan Dumpe</span></strong> <br />
Sylvesterstr. 6<br />
46348 Raesfeld<br />
Deutschland</p>
            <p><strong><span>Mail:</span></strong> info@physiotherapie-dumpe.de</p>
            <p>Wir freuen uns auf Ihre Bewerbung.</p>
          </Col>
        </Row>
      </Container>
      <ContactBanner />
    </>
  )
}
